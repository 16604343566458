import {
  addDoc,
  arrayRemove,
  arrayUnion,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "./firebase";
import { AccountData, ConnectionData, RoleType, TutorData } from "./types";
import emailjs from "@emailjs/browser";

export const createStudent = async (
  name: string,
  email: string,
  role: RoleType | undefined
) => {
  if (role === "student") {
    await setDoc(
      doc(db, "students", email),
      {
        name: name,
        email: email,
      },
      { merge: true }
    );
  }
};

export const createUser = async (
  name: string,
  email: string,
  role: RoleType
) => {
  await setDoc(
    doc(db, "users", email),
    {
      name: name,
      email: email,
      recentRole: role,
      roles: role === "tutor" ? [] : [role],
    },
    { merge: true }
  );
};

export const deleteUser = async (email: string) => {
  await deleteDoc(doc(db, "students", email)).then(async () => {
    await deleteDoc(doc(db, "tutors", email)).then(async () => {
      await updateDoc(doc(db, "admins", "adminList"), {
        administrators: arrayRemove(email),
      }).then(async () => {
        await deleteDoc(doc(db, "users", email)).then(async () => {
          console.log("User successfully deleted");
        });
      });
    });
  });
};

export const checkIfRegisteredTutor = async (
  email: string
): Promise<{
  isTutor: boolean;
  grade: string | undefined;
  days: string[];
  subjects: string[];
  isVerified: boolean;
}> => {
  const docRef = doc(db, "tutors", email);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    const data = docSnap.data();
    return {
      isTutor:
        data.subjects !== undefined &&
        data.subjects.length > 0 &&
        data.daysAvailable !== undefined &&
        data.daysAvailable.length > 0 &&
        data.grade !== undefined &&
        data.grade.length !== "",
      grade: data.grade,
      days: data.daysAvailable ? data.daysAvailable : [],
      subjects: data.subjects ? data.subjects : [],
      isVerified: data.isVerified === "verified",
    };
  }
  return {
    isTutor: false,
    grade: undefined,
    days: [],
    subjects: [],
    isVerified: false,
  };
};

export const checkIfAdmin = async (email: string): Promise<boolean> => {
  return await getAllAdminEmails().then((adminEmails) => {
    if (adminEmails.includes(email) || email === "krushaybhavsar@gmail.com") {
      return true;
    } else {
      return false;
    }
  });
};

export const updateUserRecentRole = async (
  email: string,
  name: string,
  role: RoleType
) => {
  await getUserData(email).then(async (user) => {
    if (user) {
      await setDoc(
        doc(db, "users", email),
        {
          recentRole: role,
          roles:
            user.roles.includes(role) || role === "tutor"
              ? user.roles
              : [...user.roles, role],
        },
        { merge: true }
      );
    } else {
      await createUser(name, email, role).then(() => {
        window.location.reload();
      });
    }
  });
};

export const getUserData = async (
  email: string
): Promise<AccountData | undefined> => {
  const docRef = doc(db, "users", email);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    return docSnap.data() as AccountData;
  }
  return undefined;
};

export const getAllAdminEmails = async (): Promise<string[]> => {
  const docRef = doc(db, "admins", "adminList");
  const docSnap = await getDoc(docRef);
  if (docSnap.exists() && docSnap.data()["administrators"]) {
    return docSnap.data()["administrators"];
  }
  return [];
};

export const getAllTutorEmails = async (): Promise<string[]> => {
  const querySnapshot = await getDocs(collection(db, "tutors"));
  const tutors: string[] = [];
  querySnapshot.forEach((doc) => {
    tutors.push(doc.id);
  });
  return tutors;
};

export const getAllStudentEmails = async (): Promise<string[]> => {
  const querySnapshot = await getDocs(collection(db, "students"));
  const students: string[] = [];
  querySnapshot.forEach((doc) => {
    students.push(doc.id);
  });
  return students;
};

export const getAllUserData = async (): Promise<AccountData[]> => {
  const querySnapshot = await getDocs(collection(db, "users"));
  const users: AccountData[] = [];
  querySnapshot.forEach((doc) => {
    users.push(doc.data() as AccountData);
  });
  return users;
};

export const updateTutorInfo = async (
  email: string,
  name: string,
  grade: string,
  subs: string[],
  days: string[]
) => {
  await setDoc(
    doc(db, "tutors", email),
    {
      name: name,
      grade: grade,
      subjects: subs,
      daysAvailable: days,
    },
    { merge: true }
  );
};

export const setTutorVerificationStatus = async (
  email: string,
  status: "pending" | "verified" | "rejected"
) => {
  await setDoc(
    doc(db, "tutors", email),
    {
      isVerified: status,
    },
    { merge: true }
  ).then(async () => {
    if (status === "verified") {
      await updateDoc(doc(db, "users", email), {
        roles: arrayUnion("tutor"),
      });
    }
  });
};

export const removeAdmin = async (email: string) => {
  await updateDoc(doc(db, "admins", "adminList"), {
    administrators: arrayRemove(email),
  }).then(async () => {
    await updateDoc(doc(db, "users", email), {
      roles: arrayRemove("admin"),
    });
  });
};

export const addAdmin = async (email: string) => {
  await getAllAdminEmails().then(async (adEmails) => {
    const adminEmails = [...adEmails, email];
    await setDoc(
      doc(db, "admins", "adminList"),
      { administrators: adminEmails },
      { merge: true }
    ).then(async () => {
      await updateDoc(doc(db, "users", email), {
        roles: arrayUnion("admin"),
      });
    });
  });
};

export const getUnverifiedTutors = async (): Promise<string[]> => {
  const emails: string[] = [];
  const q = query(
    collection(db, "tutors"),
    where("isVerified", "==", "pending")
  );
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    emails.push(doc.id);
  });
  return emails;
};

export const getAvailableTutorsData = async (
  email: string
): Promise<TutorData[]> => {
  const data: TutorData[] = [];
  const q = query(
    collection(db, "tutors"),
    where("isVerified", "==", "verified")
  );
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    if (doc.id !== email) {
      if (!doc.data()["students"] || !doc.data()["students"].includes(email)) {
        // if (!doc.data()["students"] || !doc.data()["students"].includes(email)) {
        data.push({
          name: doc.data()["name"],
          email: doc.id,
          grade: doc.data()["grade"],
          daysAvailable: doc.data()["daysAvailable"],
          subjects: doc.data()["subjects"],
          isVerified: doc.data()["isVerified"],
          profilePicture: doc.data()["profilePicture"]
            ? doc.data()["profilePicture"]
            : "",
        });
      }
    }
  });
  return data;
};

export const getTutorData = async (
  email: string
): Promise<TutorData | undefined> => {
  const docRef = doc(db, "tutors", email);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    return docSnap.data() as TutorData;
  }
  return undefined;
};

export const updateTutorPfp = async (email: string, pfp: string) => {
  await setDoc(
    doc(db, "tutors", email),
    {
      profilePicture: pfp,
    },
    { merge: true }
  );
};

export const sendTutorEmail = async (params: {
  student_name: string;
  student_email: string;
  tutor_email: string;
  days_available: string;
  subjects: string;
  website_url: string;
}) => {
  await emailjs.send(
    "service_ceto2o6",
    "template_rsjj09r",
    params,
    "vlvwZQiDQLv54Ft0R"
  );
};

export const sendStudentEmail = async (params: {
  tutor_email: string;
  student_email: string;
  days_available: string;
  subjects: string;
}) => {
  await emailjs.send(
    "service_ceto2o6",
    "template_x3cmqzp",
    params,
    "vlvwZQiDQLv54Ft0R"
  );
};

export const createConnection = async (connectionData: ConnectionData) => {
  await addDoc(collection(db, "connections"), {
    ...connectionData,
  }).then(async () => {
    await updateDoc(doc(db, "tutors", connectionData.tutorEmail), {
      students: arrayUnion(connectionData.studentEmail),
    });
  });
};

export const deleteConnection = async (
  id: string,
  tutorEmail: string,
  studentEmail: string
) => {
  await deleteDoc(doc(db, "connections", id)).then(async () => {
    await updateDoc(doc(db, "tutors", tutorEmail), {
      students: arrayRemove(studentEmail),
    });
  });
};

export const updateConnectionStatus = async (
  id: string,
  status: "pending" | "tutoring"
) => {
  await updateDoc(doc(db, "connections", id), {
    status: status,
  });
};

export const getAllUserConnections = async (
  email: string
): Promise<ConnectionData[]> => {
  const connections: ConnectionData[] = [];
  const tq = query(
    collection(db, "connections"),
    where("tutorEmail", "==", email)
  );
  const tqs = await getDocs(tq);
  tqs.forEach((doc) => {
    connections.push({ connectionId: doc.id, ...doc.data() } as ConnectionData);
  });
  const sq = query(
    collection(db, "connections"),
    where("studentEmail", "==", email)
  );
  const sqs = await getDocs(sq);
  sqs.forEach((doc) => {
    connections.push({ connectionId: doc.id, ...doc.data() } as ConnectionData);
  });
  const uniqueConnections = connections.filter(
    (connection, index) =>
      connections.findIndex(
        (c) => c.connectionId === connection.connectionId
      ) === index
  );
  return uniqueConnections;
};

export const getAllConnections = async () => {
  const connections: ConnectionData[] = [];
  const q = query(collection(db, "connections"));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    connections.push({ connectionId: doc.id, ...doc.data() } as ConnectionData);
  });
  return connections;
};
