import React, { useState } from "react";
import { auth, signInWithGoogle } from "../../firebase";
import {
  checkIfAdmin,
  createStudent,
  updateUserRecentRole,
} from "../../backendTools";
import { NotificationInfo, RoleType } from "../../types";
import "./CustomModal.css";

type LoginModalProps = {
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  setNotificationVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setNotificationMessage: React.Dispatch<
    React.SetStateAction<NotificationInfo>
  >;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setLoginRecentRole: React.Dispatch<
    React.SetStateAction<RoleType | undefined>
  >;
};

const LoginModal = (props: LoginModalProps) => {
  const [role, setRole] = useState<"tutor" | "student" | undefined>(undefined);

  const checkValidInput = () => {
    return role === undefined;
  };

  const handleBtnClick = async (isAdmin: boolean) => {
    await signInWithGoogle()
      .then(async (result) => {
        const email = result.user.email;
        const name = result.user.displayName;
        if (email && name) {
          if (
            email.endsWith("@students.sboe.org") ||
            email.endsWith("@sboe.org") ||
            email == "krushaybhavsar@gmail.com"
          ) {
            if (isAdmin) {
              props.setLoading(true);
              await checkIfAdmin(email)
                .then(async (hasAdmin) => {
                  if (hasAdmin) {
                    props.setLoginRecentRole("admin");
                    sucMessage("Successfully signed in as admin!");
                    props.setShow(false);
                    await updateUserRecentRole(email, name, "admin").then(
                      () => {
                        props.setLoading(false);
                      }
                    );
                  } else {
                    auth.signOut();
                    errMessage("You are not an admin!");
                    props.setLoading(false);
                  }
                  props.setLoading(false);
                })
                .catch((e) => {
                  errMessage("Error signing in");
                  console.log(e);
                  props.setLoading(false);
                });
            } else {
              if (role === "student") {
                props.setLoading(true);
                createStudent(name, email, role)
                  .then(async () => {
                    props.setLoginRecentRole("student");
                    sucMessage("Successfully signed in!");
                    props.setShow(false);
                    await updateUserRecentRole(email, name, "student")
                      .then(() => {
                        props.setLoading(false);
                      })
                      .catch((e) => {
                        errMessage("Error signing in");
                        console.log(e);
                        props.setLoading(false);
                      });
                  })
                  .catch((e) => {
                    errMessage("Error signing in");
                    console.log(e);
                    props.setLoading(false);
                  });
              } else if (role === "tutor") {
                props.setLoading(true);
                props.setLoginRecentRole("tutor");
                sucMessage("Successfully signed in!");
                props.setShow(false);
                await updateUserRecentRole(email, name, "tutor")
                  .then(() => {
                    props.setLoading(false);
                  })
                  .catch((e) => {
                    errMessage("Error signing in");
                    console.log(e);
                    props.setLoading(false);
                  });
              }
            }
          } else {
            auth
              .signOut()
              .then(() => {
                props.setLoading(false);
                errMessage(
                  "Please use a Google account administered by the Secaucus Public School District."
                );
              })
              .catch((e) => {
                props.setLoading(false);
                console.log(e);
                errMessage("Error signing in");
              });
          }
        } else {
          errMessage("Error signing in");
          props.setLoading(false);
        }
      })
      .catch((e) => {
        errMessage("Error signing in");
        console.log(e);
        props.setLoading(false);
      });
  };

  const errMessage = (msg: string) => {
    props.setNotificationMessage({
      message: msg,
      type: "error",
    });
    props.setNotificationVisible(true);
  };

  const sucMessage = (msg: string) => {
    props.setNotificationMessage({
      message: msg,
      type: "success",
    });
    props.setNotificationVisible(true);
  };

  return (
    <>
      <h1>Sign in</h1>
      <div className="modal-content-body">
        <div className="input-container">
          <p>What best describes you?</p>
          <div className="multiple-choice-container">
            <p
              className={"mc-option" + (role === "student" ? " active" : "")}
              onClick={() => {
                role === "student" ? setRole(undefined) : setRole("student");
              }}
            >
              A student looking to be tutored
            </p>
            <p
              className={"mc-option" + (role === "tutor" ? " active" : "")}
              onClick={() =>
                role === "tutor" ? setRole(undefined) : setRole("tutor")
              }
            >
              A NHS Tutor
            </p>
          </div>
        </div>
        <button
          className="cm-login-next-btn"
          onClick={() => handleBtnClick(false)}
          disabled={checkValidInput()}
        >
          <img
            src={require("../../assets/google.png")}
            alt="modal-google-logo"
            className="modal-google-logo"
          />
          <p>Sign in with Google</p>
        </button>
        <p className="login-admin-p">
          {"Are you an admin? "}
          <a onClick={() => handleBtnClick(true)}>{"Click here"}</a>
        </p>
      </div>
    </>
  );
};

export default LoginModal;
